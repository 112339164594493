import {
  addDays,
  addMinutes,
  addWeeks,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isThisWeek,
  isValid,
  startOfWeek
} from "date-fns";
import { createContext, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import ct from 'countries-and-timezones';

import { formatInTimeZone, toDate } from "date-fns-tz";
import MeetErrorImg from "../../assets/images/meet-error.png";
import MeetErrorUpdatedImg from "../../assets/images/meeterrorupdated.svg";
import BackNav from "../../components/back-nav";
import Confirmation from "../../components/confirmation";
import EventDetails, { Slot as EventSlot } from "../../components/event-details";
import Loader from "../../components/loader";
import ProfileImg from "../../components/profile-img";
import Form, { FormElement } from "../../components/question-form";
import { Slot } from "../../components/schedule-list";
import SlotList from "../../components/slots";
import WeekCalendar from "../../components/weekCalendar";
import Layout from "../../hoc/layout";
import EventService, { EventTrackPayload } from "../../services/event.service";
import ScheduleService from "../../services/schedule.service";
import ZipteamsService from "../../services/zipteams.service";
import { AppTracking } from "../../utils/appTracking";
import { APP_BASE_URL, EMBED_TYPE_POPUP } from "../../utils/constants";
import { hexToRgb, isValidEmail, searchHas, searchRemove, searchSet } from "../../utils/helper";
import { Tracking } from "../../utils/tracking";
import { Plus, X } from "react-feather";

export const ScheduledContext = createContext({ themeColor: '' });

const ParticipantBox = ({ 
  participants, 
  setParticipants, 
  openParticipantsBox, 
  setOpenParticipantsBox 
}: {
    participants: string[], 
    setParticipants: React.Dispatch<SetStateAction<string[]>>,
    openParticipantsBox: boolean;
    setOpenParticipantsBox: React.Dispatch<SetStateAction<boolean>>
}) => {
  const [inputVal, setInputVal] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  
  const forceFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  useEffect(() => {
    forceFocus();
  }, [participants, openParticipantsBox, inputVal]);
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputVal && !participants.includes(inputVal) && isValidEmail(inputVal)) {
      setParticipants([...participants, inputVal]);
      setInputVal('');
    } else if (e.key === 'Backspace' && !inputVal && participants.length) {
      const participantsCopy = [...participants];
      participantsCopy.pop();
      setParticipants(participantsCopy);
    }
    requestAnimationFrame(forceFocus);
  };

  return (
    <div className="mb-4 mt-3">
      <button 
        type="button" 
        className="flex items-center gap-1 border-0 font-bold"
        onClick={() => {
          setOpenParticipantsBox(true);
          requestAnimationFrame(forceFocus);
        }}
      >
        {!openParticipantsBox && (
          <Plus className="w-4.5 h-4.5"/>
        )}
        Add Participant
      </button>
      {openParticipantsBox && (
        <div className="w-full h-24 p-2 mt-2 rounded-md border border-app-input-border text-sm flex flex-wrap gap-2 overflow-y-auto">
          {participants && participants.map((email: string) => (
            <div key={email} style={{ backgroundColor: 'rgb(225, 235, 239)' }} className="relative rounded py-2 pl-2 pr-3.5 text-sm font-medium h-max">
              {email}
              <button 
                type="button" 
                className="absolute right-1 top-1" 
                onClick={(e) => {
                  e.stopPropagation();
                  const newArray = participants.filter(mail => mail !== email);
                  setParticipants(newArray);
                }}
              >
                <X className="w-2 h-2 text-primary-dark" />
              </button>
            </div>
          ))}
          <input 
            ref={inputRef}
            id="participant-inp"
            value={inputVal}
            autoFocus={true}
            className="w-full outline-0 border-0 h-9 text-sm flex items-center pointer-events-auto z-auto static opacity-100"
            onChange={(e) => setInputVal(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
    </div>
  );
};

function ScheduledApp(): JSX.Element {
  const location = useLocation();
  const { pathname, search, state } = location;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [embedType, setEmbedType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [schedule, setSchedule] =
    useState<
      Record<string, { start_time: string; end_time: string; slots: number }[]>
    >();
  const [offlineSchedule, setOfflineSchedule] =
    useState<
      Record<string, { start_time: string; end_time: string; slots: number }[]>
    >();
  const [slots, setSlots] = useState<{ startTime: Date; endTime: Date }[]>();
  const [offlineSlots, setOfflineSlots] = useState<{ startTime: Date; endTime: Date }[]>();
  const [dateSelectability, setDateSelectability] = useState<
    Record<string, boolean>
  >({});
  const [selectedSlot, setSelectedSlot] = useState<Slot>();
  const [confirmedSlot, setConfirmedSlot] = useState<EventSlot>();
  const [scheduledSlot, setScheduledSlot] = useState<EventSlot>();
  const [schedulingFailed, setSchedulingFailed] = useState<boolean>();
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) || 'Asia/Calcutta'
  );

  const [eventId, setEventId] = useState<number>();
  const [eventName, setEventName] = useState<string>("");
  const [eventDuration, setEventDuration] = useState<number>(0);
  const [eventDescription, setEventDescription] = useState<string>("");
  const [eventStartDate, setEventStartDate] = useState<Date>();
  const [eventEndDate, setEventEndDate] = useState<Date>();
  const [realtimeEventSlug, setRealtimeEventSlug] = useState<string>("");
  const [currentWeek, setCurrentWeek] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [slotPage, setSlotPage] = useState<number>(0);
  const [themeColor, setThemeColor] = useState<string>("#31667f");

  const [questions, setQuestions] = useState<FormElement[]>([]);
  const [prefill, setPrefill] = useState<{ [key: string]: string }>();

  const [organisationName, setOrganisationName] = useState<string>("");
  const [organisationId, setOrganisationId] = useState<number>();
  const [organisationLogo, setOrganisationLogo] = useState<string>('');

  const [meetLink, setMeetLink] = useState<URL>();
  const [userAvatar, setUserAvatar] = useState<string>('');
  const [UTMFlags, setUTMFlags] = useState<Record<string, string>>({});
  const [prefillPhoneNumber, setPrefillPhoneNumber] = useState<string>('');
  const [prefillEmail, setPrefillEmail] = useState<string>('');
  const [workspaceId, setWorkspaceId] = useState<number>();
  const [isUserAvailable, setIsUserAvailable] = useState<boolean>(false);
  const [frame, setFrame] = useState<string>('');
  const [parentURL, setParentURL] = useState<string>('');
  const [prefillCustomerName, setPrefillCustomerName] = useState<string>('');
  const [prefillCompanyName, setPrefillCompanyName] = useState<string>('');
  const [isCRMPrefill, setIsCRMPrefill] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [businessEmail, setBusinessEmail] = useState<boolean>(false);
  const [prefillType, setPrefillType] = useState<string>('');
  const [allowRestartFlow, setAllowRestartFlow] = useState<boolean>(true);
  const [siteLocation, setSiteLocation] = useState<string>('');
  const [offlineSlotsEnabled, setOfflineSlotsEnabled] = useState<boolean>(false);
  const [slotMode, setSlotMode] = useState<string>('online');
  const [isScheduleLoading, setIsScheduleLoading] = useState<boolean>(true);
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(true);
  const [instantScheduledBookingEnabled, setInstantScheduledBookingEnabled] = useState<boolean>(false);
  const [isInstantSlot,setIsInstantSlot] = useState<boolean>(false);
  const [showOnlyOfflineSlots, setShowOnlyOfflineSlots] = useState<boolean>(false);
  // const [visibleStepIds, setVisibleStepIds] = useState<{[key: string] : string}>({});
  const [countryFromTz, setCountryFromTz] = useState<string>('in');
  const [wpSubscriptionTier, setWpSubscriptionTier] = useState<string>('');
  const [participants, setParticipants] = useState<string[]>([]);
  const [participantBoxOpen, setParticipantBoxOpen] = useState<boolean>(false);

  const referrer = (state as { [key: string]: string })?.referrer || searchParams.get('referrer');

  // const isSelectedMonthChanged = useRef<boolean>(false);
  // const isDateFirstTimeSelected = useRef<boolean>(false);
  
  const visibleStepIds = useRef<{[key: string] : string}>({});
  const isFirstLoad = useRef<boolean>(true);
  const slotManuallyChanged = useRef<boolean>(false);
  const selectedDateRef = useRef<Date>();
  const parentURLRef = useRef<string>('');

  useEffect(() => {

    if (eventId && schedulingFailed) {
      window.parent.postMessage(
        {
          type: "onPageChange",
          value: 'scheduled_allocation_failed_page'
        },
        "*"
      );
      return;
    }

    if (!eventId && !isLoading) {
      window.parent.postMessage(
        {
          type: "onPageChange",
          value: 'scheduled_link_error_page'
        },
        "*"
      );
      return;
    }

    if (eventId && scheduledSlot) {
      window.parent.postMessage(
        {
          type: "onPageChange",
          value: 'scheduled_meeting_confirmation_page'
        },
        "*"
      );
      return;
    }

    if (eventId && confirmedSlot) {
      window.parent.postMessage(
        {
          type: "onPageChange",
          value: 'scheduled_form_details_page'
        },
        "*"
      );
      return;
    }

    if (eventId && !confirmedSlot) {
      window.parent.postMessage(
        {
          type: "onPageChange",
          value: 'scheduled_date&time_page'
        },
        "*"
      );
      return;
    }

  }, [eventId, confirmedSlot, scheduledSlot, schedulingFailed]);

  const setVisibleStepIds = (param: {[key: string] : string}) => {
    visibleStepIds.current = param;
  }

  const App = (): JSX.Element => {
    return (
      <div
        className={`md:mt-8 lg:mt-16 md:mb-7 flex-auto flex justify-center md:max-h-[700px] relative h-full`}
      >
        <div className="flex flex-col w-full flex-auto items-center">
          {isLoading ? (
            <Loader />
          ) : eventId ? (
            <div
              className={`
              relative w-full h-full md:border md:border-primary-dark items-center lg:items-stretch
              md:border-opacity-10 md:shadow-md md:rounded-lg bg-white
              flex-auto flex flex-col lg:flex-row md:justify-end md:max-w-[80%] ${
                confirmedSlot && !scheduledSlot ? `lg:max-w-5xl` : `lg:max-w-[800px]`
              } ${embedType === EMBED_TYPE_POPUP ? 'max-w-[400px]' : ''}
            `}
            >
              {!scheduledSlot ? (
                schedulingFailed ? (
                  <div
                    className={`flex-auto h-full max-w-[450px] mx-auto py-7 px-4 md:px-0 flex flex-col`}
                  >
                    <div className="mt-4 sm:mt-12">
                      <img
                        className={`m-auto`}
                        src={MeetErrorUpdatedImg}
                        alt="meet-error"
                        referrerPolicy="no-referrer"
                      />
                    </div>
                    <div className="mt-3 w-full flex-auto md:flex-none">
                      <div className="font-bold text-xl leading-6 text-center mx-auto w-[90%]">
                        Sorry! We are not able to schedule your meeting right now.{" "}
                        {allowRestartFlow && (
                          <>
                            <a href={new URL(
                              `/${params.org}${params.slug ? `/${params.slug}` : ''}${search}`,
                              APP_BASE_URL
                            ).href}>
                              <span className="font-bold text-[#31667f] hover:underline">
                                Click here
                              </span>
                            </a> to try again.
                          </>
                        )}
                      </div>
                    </div>
                    <div className="text-secondary-dark text-sm leading-4 text-center mt-6">
                      Please get in touch with the host in case this issue
                      persists.
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`
                      w-full lg:border-b-0 lg:border-r border-r-primary-dark border-opacity-10 
                      text-base flex flex-row lg:flex-col leading-6 
                      ${!confirmedSlot ? 'hidden lg:block' : ''}
                      ${embedType !== EMBED_TYPE_POPUP ? 'border-b' : 'hidden'}
                      ${confirmedSlot && !scheduledSlot ? `lg:w-[70%]` : `lg:w-[85%]`}`}
                    >
                      <div
                        className={`w-full ${
                          embedType !== EMBED_TYPE_POPUP ? confirmedSlot ? "p-3" : "p-4" : 'p-2'
                        } lg:p-6 hidden xsh:flex sm:flex sm:flex-auto flex-col md:overflow-y-auto justify-center lg:justify-start`}
                      >
                        {confirmedSlot ? (
                          <div className={`hidden md:block lg:mb-4`}>
                            <BackNav
                              onClick={() => {
                                navigate(
                                  `/${params.org}${
                                    params.slug ? `/${params.slug}` : ""
                                  }${search}`,
                                  {
                                    replace: true,
                                    state: {
                                      referrer,
                                      name: prefillCustomerName,
                                      company: prefillCompanyName,
                                      number: prefillPhoneNumber,
                                      email: prefillEmail,
                                      prefilltype: prefillType,
                                    },
                                  }
                                );
                              }}
                            />
                          </div>
                        ) : (
                          (referrer === "realtime" ||
                            referrer === "realtimev2") && (
                            <div className={`hidden xsh:flex sm:flex lg:mb-4`}>
                              <BackNav
                                onClick={() => {
                                  if (
                                    referrer === "realtimev2" &&
                                    embedType === EMBED_TYPE_POPUP
                                  ) {
                                    window.parent.postMessage(
                                      {
                                        type: frame === 'fullframe' ? "backToRealtimeFrame" : "backToRealtime",
                                        src: `${meetLink?.href}&${
                                          prefill
                                            ? searchSet(
                                                `${search}`,
                                                "prefill",
                                                JSON.stringify(prefill)
                                              )
                                            : search
                                        }${prefillCustomerName ? `&name=${prefillCustomerName}` : ''}
                                        ${prefillCompanyName ? `&company=${prefillCompanyName}` : ''}
                                        ${prefillPhoneNumber ? `&number=${prefillPhoneNumber}` : ''}
                                        ${prefillEmail ? `&email=${prefillEmail}` : ''}`,
                                      },
                                      "*"
                                    );
                                  } else {
                                    navigate(
                                      {
                                        pathname: `${realtimeEventSlug}`,
                                        search: `${
                                          prefill
                                            ? searchSet(
                                                `${search}`,
                                                "prefill",
                                                JSON.stringify(prefill)
                                              )
                                            : searchRemove(
                                                searchRemove(search, "date"),
                                                "month"
                                              )
                                        }`,
                                      },
                                      {
                                        replace: false,
                                        state: {
                                          referrer,
                                          name: prefillCustomerName,
                                          company: prefillCompanyName,
                                          number: prefillPhoneNumber,
                                          email: prefillEmail,
                                          prefilltype: prefillType,
                                        },
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )
                        )}
                        {embedType !== EMBED_TYPE_POPUP && (
                          <EventDetails
                            organisationId={organisationId || 0}
                            organisationName={organisationName}
                            organisationLogo={organisationLogo}
                            name={eventName}
                            duration={eventDuration}
                            conferencingDetails={
                              "Web conferencing details provided upon confirmation."
                            }
                            description={eventDescription}
                            confirmedSlot={confirmedSlot}
                            embedType={embedType}
                          />
                        )}
                      </div>
                    </div>

                    {confirmedSlot ? (
                      <div
                        className={`flex-auto flex pt-4 flex-col p-5 md:p-7 w-full items-center lg:items-start overflow-y-auto`}
                      >
                        <div
                          className={`w-full h-full max-w-xl lg:max-w-[60%] flex-auto flex flex-col mx-auto md:mx-0`}
                        >
                          <div className={`flex items-center relative`}>
                            <div
                              className={`flex md:hidden mb-5 absolute top-0 left-0`}
                            >
                              <BackNav
                                onClick={() => {
                                  navigate(
                                    `/${params.org}${
                                      params.slug ? `/${params.slug}` : ""
                                    }${search}`,
                                    {
                                      replace: true,
                                      state: {
                                        referrer,
                                        name: prefillCustomerName,
                                        company: prefillCompanyName,
                                        number: prefillPhoneNumber,
                                        email: prefillEmail,
                                        prefilltype: prefillType,
                                      },
                                    }
                                  );
                                }}
                              />
                            </div>
                            {/* <div
                              className={`w-full text-primary-dark text-xl leading-5 font-bold mb-5 flex justify-center`}
                            >
                              <span
                                className={`w-full text-center lg:ml-2 lg:text-left`}
                              >
                                Enter Details
                              </span>
                            </div> */}
                          </div>
                          <div
                            className={`h-full flex flex-auto justify-center lg:items-start`}
                          >
                            <div
                              className={`flex flex-col md:pr-3 w-[100%] lg:w-full lg:pl-4`}
                            >
                              <Form
                                country={countryFromTz}
                                prefillPhoneNumber={prefillPhoneNumber}
                                prefillEmail={prefillEmail}
                                prefillCustomerName={prefillCustomerName}
                                prefillCompanyName={prefillCompanyName}
                                isCRMPrefill={isCRMPrefill}
                                form={questions}
                                prefill={prefill}
                                submitText="Schedule Event"
                                avatar={userAvatar}
                                embedType={embedType || ""}
                                businessEmail={businessEmail}
                                setVisibleStepIds={setVisibleStepIds}
                                handleSubmit={async (data: Record<string, string>) => {
                                  Tracking.eventFormSubmit();
                                  
                                  if (embedType !== EMBED_TYPE_POPUP) {
                                    AppTracking.scheduledFormSubmit(workspaceId || 0, {
                                      event_id: eventId,
                                      is_realtime: false,
                                      url: window.location.href,
                                      timestamp: new Date().toJSON(),
                                      utm_params: {
                                        ...(UTMFlags)
                                      },
                                      source: 'URL',
                                      userAvailable: isUserAvailable,
                                      workspace_id: workspaceId
                                    });
                                  }
                                  
                                  window.parent.postMessage(
                                    {
                                      type: "onFormSubmit",
                                      workspaceId: workspaceId,
                                      meta: {
                                        event_id: eventId,
                                        is_realtime: false,
                                        url: window.location.href,
                                        timestamp: new Date().toJSON(),
                                        utm_params: {},
                                        source: embedType === EMBED_TYPE_POPUP ? frame === 'fullframe' ? 'buttonwidget' : 'floatingwidget' : 'URL',
                                        userAvailable: isUserAvailable,
                                        workspace_id: workspaceId
                                      }
                                    },
                                    "*"
                                  );

                                  // await onFormSubmit(data, confirmedSlot)
                                  // const newData: {[key:string]: string } = {};
                                  // const newUtmObject: {[key:string]: string } = {};
                                  // questions && questions.map((item) => {
                                  //   if (item.type === 'utm_params') {
                                  //     const id = item?.id;
                                  //     const utmData = Object.keys(data).filter(itm => itm.includes(`${id}-`));
                                  //     if (utmData?.length) {
                                  //       utmData.map(item => {
                                  //         const label = item.replace(`${id}-`, '');
                                  //         newUtmObject[label] = data?.[item];
                                  //       })
                                  //     }
                                  //     newData[item?.id] = '';
                                  //   } else {
                                  //     newData[item?.id] = data?.[item?.id];
                                  //   }
                                  // });
                                  // const UTMData = (Object.keys(newUtmObject).length && newUtmObject) || (Object.keys(UTMFlags).length && UTMFlags);
                                
                                  try {
                                    const response =
                                      await ScheduleService.postSchedule({
                                        event_id: Number(eventId),
                                        time: confirmedSlot?.startTime.toString() ||"",
                                        answers: data,
                                        timezone: selectedTimeZone,
                                        source_url: (parentURLRef.current || parentURL) || window.location.href,
                                        slot_mode: slotMode,
                                        instant_slot: isInstantSlot || false,
                                        ...(UTMFlags && Object.keys(UTMFlags)?.length ? {utm_flags: {...UTMFlags}} : {}),
                                        ...(participants?.length ? { participants } : {})
                                      });
                                    const {
                                      room,
                                      location,
                                      booking,
                                      event
                                    } = response?.data;
                                    
                                    const bookingId = booking?.id;
                                    const scheduledTime = booking?.time || '';
                                    const meetUrl = room?.url || '';
                                    const meetRoomId = room?.roomId || '';
                                    const slug = event?.slug || '';
                                    
                                    let url: URL | null = null;
                                    if (room?.url) {
                                      url = new URL(room?.url);
                                      setMeetLink(url);
                                    }

                                    // 2024-11-08T21:00:00+08:00
                                    const bookedSlot = formatInTimeZone(
                                      booking?.time,
                                      booking?.timezone,
                                      "yyyy-MM-dd'T'HH:mm:ssxxx"
                                    ) 
                                    
                                    const payload = {
                                      bookedSlot,
                                      scheduledTime,
                                      meetUrl,
                                      rescheduleUrl: `${window.origin}${slug}/reschedule-meeting?id=${bookingId}&room=${meetRoomId}`,
                                      cancelUrl: `${window.origin}${slug}/cancel-meeting?id=${bookingId}&room=${meetRoomId}`,
                                    }
                                    setScheduledSlot(confirmedSlot);
                                    
                                    window.parent.postMessage(
                                      {
                                        type: "zt_meeting_confirmed",
                                        value: payload
                                      },
                                      "*"
                                    );
                                    setSiteLocation(location);
                                  } catch (err: any) {
                                    if (err?.response?.status === 409) {
                                      if (err?.response?.data?.code === 'SLOT_NOT_AVAILABLE') {
                                        Tracking.eventSlotNotAvailable();
                                      } else if (err?.response?.data?.code === 'COULD_NOT_ALLOCATE_USER'){
                                        Tracking.eventCouldNotAllocateUser()
                                      }
                                      const newURL = new URL(window.location.href);
                                      newURL.searchParams.delete('date');
                                      newURL.searchParams.delete('month');

                                      window.location.href = new URL(
                                        `/${params.org}${params.slug ? `/${params.slug}` : ''}${newURL?.search ? `${newURL?.search}&referrer=noslot`: '?referrer=noslot'}${prefill ? `&prefill=${encodeURIComponent(JSON.stringify(prefill))}` : ''}`,
                                        APP_BASE_URL
                                      ).href
                                    } else {
                                      setSchedulingFailed(true);
                                    }
                                  } finally {
                                    setIsInstantSlot(false);
                                  }
                                }}
                                organisationId={organisationId || 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`flex flex-col w-full h-full`}>
                        <div
                          className={`flex-auto flex flex-col md:flex-row pt-4 p-5`}
                        >
                          <div
                            className={`flex flex-col flex-auto w-full ${
                              embedType === EMBED_TYPE_POPUP ? "pb-0" : "pb-6"
                            }`}
                          >
                            <div className={`flex mb-2`}>
                              {(referrer === "realtime" || referrer === "realtimev2") && (
                                <div className={`flex md:hidden`}>
                                  <BackNav
                                    onClick={() => {
                                      if (
                                        referrer === "realtimev2" &&
                                        embedType === EMBED_TYPE_POPUP
                                      ) {
                                        window.parent.postMessage(
                                          {
                                            type: frame === 'fullframe' ? "backToRealtimeFrame" : "backToRealtime",
                                            src: `${meetLink?.href}&${
                                              prefill
                                                ? searchSet(
                                                    `${search}`,
                                                    "prefill",
                                                    JSON.stringify(prefill)
                                                  )
                                                : search
                                            }${prefillCustomerName ? `&name=${prefillCustomerName}` : ''}
                                            ${prefillCompanyName ? `&company=${prefillCompanyName}` : ''}
                                            ${prefillPhoneNumber ? `&number=${prefillPhoneNumber}` : ''}
                                            ${prefillEmail ? `&email=${prefillEmail}` : ''}`,
                                          },
                                          "*"
                                        );
                                      } else {
                                        navigate(
                                          {
                                            pathname: `${realtimeEventSlug}`,
                                            search: `${
                                              prefill
                                                ? searchSet(
                                                    `${search}`,
                                                    "prefill",
                                                    JSON.stringify(prefill)
                                                  )
                                                : searchRemove(
                                                    searchRemove(
                                                      search,
                                                      "date"
                                                    ),
                                                    "month"
                                                  )
                                            }`,
                                          },
                                          {
                                            replace: false,
                                            state: {
                                              referrer,
                                              name: prefillCustomerName,
                                              company: prefillCompanyName,
                                              number: prefillPhoneNumber,
                                              email: prefillEmail,
                                              prefilltype: prefillType,
                                            },
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                              {/* <div
                                className={`w-full text-primary-dark text-xl leading-5 font-bold text-center`}
                              >
                                Select a Date & Time
                              </div> */}
                            </div>
                            <div className="flex gap-2 items-center">
                              <ProfileImg avatar={userAvatar} small embedType={embedType || ''} />
                              <div className={`w-auto font-bold text-xl leading-6 ml-3 md:ml-4`}>
                              {referrer === 'forced' || referrer === 'noslot' ? (
                                <>
                                  {referrer === 'forced' && (
                                    <span>Sorry, I won’t be able to connect instantly. Will any of these slots work for you?</span>
                                  )}
                                  {referrer === 'noslot' && (
                                    <span>Sorry, this time slot got blocked. Can you please choose another slot?</span>
                                  )}
                                </>
                              ) : (
                                <span>What day & time works best  for you?</span>
                              )}
                              </div>
                            </div>
                            <div className={`mb-4`}>
                              <WeekCalendar
                                month={new Date()}
                                currentWeek={currentWeek}
                                dateSelectability={dateSelectability}
                                selectedDate={selectedDate}
                                startDate={eventStartDate}
                                endDate={eventEndDate}
                                weekStartsOn={1}
                                onMonthChange={(date: Date) => {
                                  // const month = format(date, "yyyy-MM")
                                  // isSelectedMonthChanged.current = true
                                  // navigate(
                                  //   `${pathname}${searchSet(
                                  //     search,
                                  //     "month",
                                  //     month
                                  //   )}`,
                                  //   { replace: true }
                                  // );
                                }}
                                onWeekChange={(weekStartDate: Date) => {
                                  setCurrentWeek(weekStartDate);
                                  slotManuallyChanged.current = false;
                                }}
                                onDateSelected={(date: Date) => {
                                  setSelectedDate(date);
                                  setSlotPage(0);
                                  selectedDateRef.current = date;
                                  slotManuallyChanged.current = false;
                                  // const formattedMonth = format(
                                  //   date,
                                  //   "yyyy-MM"
                                  // );
                                  // const formattedDate = format(
                                  //   date,
                                  //   "yyyy-MM-dd"
                                  // );
                                  // let params = searchSet(
                                  //   search,
                                  //   "month",
                                  //   formattedMonth
                                  // );
                                  // params = searchSet(
                                  //   params,
                                  //   "date",
                                  //   formattedDate
                                  // );
                                  // navigate(`${pathname}${params}`, {
                                  //   replace: true,
                                  //   state: { referrer },
                                  // });
                                }}
                                scheduleLoading={isScheduleLoading}
                              />
                            </div>
                            {!(offlineSlotsEnabled && showOnlyOfflineSlots) && (
                              <div className="mb-4 md:max-w-[400px]">
                                <SlotList
                                  title={offlineSlotsEnabled ? 'Video counselling' : 'Time Slots'}
                                  slotLines={(embedType === EMBED_TYPE_POPUP &&  frame !== 'fullframe') || offlineSlotsEnabled ? 1 : 2}
                                  embedType={embedType || ''}
                                  timeZone={selectedTimeZone}
                                  slots={slots ? slots : []}
                                  selectedSlot={slotMode === 'online' ? selectedSlot : undefined}
                                  currentPage={slotPage}
                                  changecurrentPage={(page: number) => {
                                    setSlotPage(page);
                                  }}
                                  onSlotSelected={(slot) => {
                                    slotManuallyChanged.current = true;
                                    setSlotMode('online');
                                    setSelectedSlot({
                                      startTime: slot.startTime,
                                      endTime: slot.endTime,
                                    });
                                    Tracking.eventSlotSelected(
                                      formatInTimeZone(
                                        slot.startTime,
                                        selectedTimeZone,
                                        "yyyy-MM-dd'T'HH:mm:ssxxx"
                                      )
                                    );
                                  }}
                                  slotsLoading={isSlotsLoading || isScheduleLoading}
                                />
                                {/* {selectedDate ? (
                                  <></>
                                ) : (
                                  <div>
                                    <div className="text-base leading-5 font-bold text-primary-dark mb-3 underline">
                                      {offlineSlotsEnabled ? 'Online Slots' : 'Time Slots'}
                                    </div>
                                    {isSlotsLoading || !selectedDate ? (
                                      <SlotsLoader />
                                    ) : (
                                      <div className='flex align-middle items-end text-sm'>No slots available</div>
                                    )}
                                  </div>
                                )} */}
                              </div>
                            )}
                            {offlineSlotsEnabled && (
                              <div className="mb-4 md:max-w-[400px]">
                                <SlotList
                                  title="In centre counselling"
                                  slotLines={(embedType === EMBED_TYPE_POPUP &&  frame !== 'fullframe') || offlineSlotsEnabled ? 1 : 2}
                                  embedType={embedType || ''}
                                  timeZone={selectedTimeZone}
                                  slots={offlineSlots ? offlineSlots : []}
                                  selectedSlot={slotMode === 'offline' ? selectedSlot : undefined}
                                  currentPage={slotPage}
                                  changecurrentPage={(page: number) => {
                                    setSlotPage(page);
                                  }}
                                  onSlotSelected={(slot) => {
                                    slotManuallyChanged.current = true;
                                    setSlotMode('offline');
                                    setSelectedSlot({
                                      startTime: slot.startTime,
                                      endTime: slot.endTime,
                                    });
                                    Tracking.eventSlotSelected(
                                      formatInTimeZone(
                                        slot.startTime,
                                        selectedTimeZone,
                                        "yyyy-MM-dd'T'HH:mm:ssxxx"
                                      )
                                    );
                                  }}
                                  slotsLoading={isSlotsLoading || isScheduleLoading}
                                />
                                {/* {selectedDate ? (
                                  <></>
                                ) : (
                                  <div>
                                    <div className="text-base leading-5 font-bold text-primary-dark mb-3 underline">
                                      Offline Slots
                                    </div>
                                    {isSlotsLoading ? (
                                      <SlotsLoader />
                                    ) : (
                                      <div className='flex align-middle items-end text-sm'>No slots available</div>
                                    )}
                                  </div>
                                )} */}
                              </div>
                            )}
                            <div className="mb-4">
                              <TimezoneSelect
                                value={selectedTimeZone}
                                labelStyle="original"
                                onChange={(timezone) => {
                                  setSelectedTimeZone(timezone.value);
                                }}
                              />
                            </div>
                            <ParticipantBox 
                              participants={participants}
                              setParticipants={setParticipants}
                              openParticipantsBox={participantBoxOpen}
                              setOpenParticipantsBox={setParticipantBoxOpen}
                            />
                            <div id="zt_confirm_slot_cnt" className={`${instantScheduledBookingEnabled ? `flex flex-col gap-3` : ''} ${embedType === EMBED_TYPE_POPUP ? '' : 'mt-6'}`}>
                              <button
                                id="zt_confirm_slot_cta"
                                type="button"
                                className={`mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3.5 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none`}
                                disabled={!selectedDate || !selectedSlot}
                                onClick={() => onSlotConfirmed()}
                              >
                                {instantScheduledBookingEnabled ? `Book this slot` : 'Confirm'}
                              </button>
                              {instantScheduledBookingEnabled && (  
                                <div className="flex justify-center gap-2 mx-auto font-bold">
                                  <span className="no-underline">Or</span>
                                  <button
                                    type="button"
                                    className="cursor-pointer bg-transparent rounded-2xl w-[165px] text-base leading-5 font-bold underline flex items-center justify-center text-primary-dark disabled:bg-opacity-60 disabled:pointer-events-none"
                                    disabled={!selectedDate || !selectedSlot}
                                    onClick={() => {
                                      setIsInstantSlot(true);
                                      onSlotConfirmed(true);
                                    }}
                                  >
                                    Start Now
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              ) : (
                <div
                  className={`${
                    embedType === EMBED_TYPE_POPUP ? "h-full" : ""
                  } flex flex-col w-full items-center`}
                >
                  <Confirmation
                    avatar={userAvatar}
                    organisationName={organisationName}
                    eventName={eventName}
                    meetURL={meetLink}
                    location={siteLocation || ''}
                    scheduledSlot={scheduledSlot}
                    embedType={embedType}
                    allowRestartFlow={allowRestartFlow}
                    onRestartFlow={() =>
                      (window.location.href = new URL(
                        `/${params.org}${params.slug ? `/${params.slug}` : ''}${search}`,
                        APP_BASE_URL
                      ).href)
                    }
                  />
                </div>
              )}
              {!isLoading && scheduledSlot && wpSubscriptionTier !== 'enterprise_tier' &&  <div className="absolute bottom-0 right-0 text-xs p-2 px-4 leading-4 flex items-center gap-[3px]">Powered by <a className="font-fjalla text-[#31667F] mt-[1px]" href="https://zipteams.com/" target="_blank" rel="noreferrer">Zipteams</a></div>}
            </div>
          ) : (
            <div
              className={`w-full md:border md:border-primary-dark items-center
            md:border-opacity-10 md:shadow-md md:rounded-lg bg-white p-6 text-center
            flex-auto flex flex-col justify-center md:max-w-[80%] lg:max-w-[800px]`}
            >
              <img className={`mb-8`} src={MeetErrorImg} alt="meet-error" />
              <span className={`text-2xl font-bold mb-2`}>
                Oops! It seems this link is not available.
              </span>
              <span className={`text-secondary-dark font-bold`}>
                It looks like this scheduling link is incorrect or made inactive
                by the host.
              </span>
            </div>
          )}
          {/* {embedType !== EMBED_TYPE_POPUP && !isLoading && (
            <div className="mx-auto mt-8 mb-4 my-auto">
              Click here to get your own{" "}
              <a
                href="https://zipteams.com/meeting-scheduling/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="font-bold text-primary underline">
                  zipme.at
                </span>
              </a>{" "}
              link
            </div>
          )} */}
        </div>
      </div>
    );
  };

  const setAppThemeColor = (value: string) => {
    document.documentElement.style.setProperty(
      "--color-secondary",
      hexToRgb(
        value.includes("#") ? value : `#${value}` || "#31667f"
      )
    );
  }

  const postEventTrack = async (id: number) => {
    try {
      const eventPayload: EventTrackPayload = {
        url: embedType === EMBED_TYPE_POPUP ? document.referrer : window.location.href,
        timestamp: new Date().toJSON(),
        event_id: id,
        is_realtime: false
      }
      await EventService.trackEventUrl(eventPayload);
    } catch(err) {
      console.log(err);
    }
  }

  const getPrefillData = () => {
    const prefillData = searchParams.get("prefill") || "";
    if (prefillData) {
      const parsedData: { [key: string]: string } =
        typeof prefillData === "string" ? JSON.parse(prefillData) : prefillData;
      return parsedData
    }
    return '';
  };

  const fetchContactInfo = async (orgId: number, phonenumber: string, email: string) => {
    try {
      if (!orgId) return [];
      const params = {
        ...(phonenumber ? { phone_number: phonenumber } : {}),
        ...(email ? { email } : {}),
      }
      const contactInfo = await ZipteamsService.getContactInfo(orgId, params);
      return contactInfo?.data?.data;
    } catch (err) {
      console.log(err);
      return []; 
    }
  }

  const loadEventData = async (userId: string = '', prefilltype: string = '') => {
    setIsLoading(true);
    try {
      const response = await EventService.getEvent(
        `/${params.org}${params.slug ? `/${params.slug}` : ''}`,
        userId
      );
      const {
        event,
        organisation,
        questions,
        available_users,
        defaultWidgetAvatar,
        selected_user,
        meta
      } = response?.data;
      
      const openType = searchParams.get("opentype");
      const embed = searchParams.get("embed_type");
      const frame = searchParams.get("frame");

      const realtimeEvent = meta && meta?.length && meta.find((itm: any) => itm?.is_realtime);
      
      if (
        realtimeEvent?.id && 
        realtimeEvent?.slug && 
        realtimeEvent?.status === 'active' && 
        embed !== EMBED_TYPE_POPUP && 
        openType !== "link"
      ) {
        return navigate(`${realtimeEvent?.slug}/instant${search}`, {
          replace: false,
          state: { prefill: prefill },
        });
      }

      if (
        available_users &&
        available_users?.length &&
        openType !== "link" &&
        embed !== EMBED_TYPE_POPUP &&
        event?.realtime_event &&
        event?.realtime_event?.slug
      ) {
        return navigate(`${event?.realtime_event?.slug}/instant${search}`, {
          replace: false,
          state: { prefill: prefill },
        });
      }

      let prefillObjectTop :any = getPrefillData();
      if (prefillObjectTop && Object.keys(prefillObjectTop)?.length) {
        const prefillObject:any = {}
        for (let index = 0; index < questions?.length; index ++) {
          const item = questions?.[index];
          prefillObject[item?.label] = prefillObjectTop?.[item?.label] || ''
        }
        setPrefill({...prefillObjectTop, ...prefillObject});
        prefillObjectTop = {...prefillObjectTop, ...prefillObject};
      }

      let phonenumber = searchParams.get("number") || (state as { [key: string]: string })?.number;
      if (phonenumber && phonenumber.includes(' ') && phonenumber.indexOf(' ') === 0) {
        phonenumber = phonenumber.replace(' ', '+');
      }
      if (phonenumber && phonenumber.includes(' ')) {
        phonenumber = phonenumber.replaceAll(' ', '');
      }
      if (!phonenumber) {
        const prefillData = getPrefillData();
        const question = questions && questions?.find((item: any) => item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')
        phonenumber = (prefillData && prefillData[question?.label || '']) || '';
        if (phonenumber && phonenumber.includes(' ')) {
          phonenumber = phonenumber.replaceAll(' ', '');
        }
      }
      let email = searchParams.get('email') || (state as { [key: string]: string })?.email;
      if (!email) {
        const prefillData = getPrefillData();
        const question = questions && questions?.find((item: any) => item?.type === 'zt_contact_email' || item?.type === 'email')
        email = (prefillData && prefillData[question?.label || '']) || '';
      }
      if (organisation?.id && (phonenumber || email)) {
        const prefillData = getPrefillData();
        const contactInfo = await fetchContactInfo(organisation?.id, phonenumber, email); 
        if (contactInfo && contactInfo?.length) {
          const prefillObject:any = {}
          for (let index = 0; index < questions?.length; index ++) {
            const item = questions?.[index];
            prefillObject[item?.label] = prefillObjectTop?.[item?.label] || '';
            if (item?.type === 'zt_customer_name' || (item?.type === 'single_line' && item?.label.toLowerCase() === 'company name')) {
              prefillObject[item?.label] = contactInfo?.[0]?.cust_name || ''
            }
            if (item?.type === 'zt_contact_name') {
              prefillObject[item?.label] = contactInfo?.[0]?.con_name || '';
            }
            if ((item?.type === 'zt_contact_email' || item?.type === 'email')) {
              prefillObject[item?.label] = contactInfo?.[0]?.con_email || '';
            }
            if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')) {
              prefillObject[item?.label] = contactInfo?.[0]?.con_phone || '';
            }
          }
          
          setPrefill({...prefillData, ...prefillObject});
          prefillObjectTop = {...prefillData, ...prefillObject};
          setIsCRMPrefill(true);
        }
      }

      setEventId(event?.id);
      setEventName(event?.name);
      setEventDuration(event?.duration);
      setEventDescription(event?.description);
      setEventStartDate(toDate(event?.start_date));
      setWorkspaceId(organisation?.workspace?.id);
      setIsUserAvailable(Boolean((available_users && available_users?.length) || false));
      setBusinessEmail(event?.business_email);
      setShowOnlyOfflineSlots(event?.only_offline);
      setWpSubscriptionTier(organisation?.workspace?.subscription_tier || 'free_tier');

      const tz = (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone);
      const country = ct.getCountryForTimezone(tz || event?.timezone || 'Asia/Kolkata')
      setCountryFromTz(country?.id?.toLowerCase() || 'in');

      const themecolor =
        searchParams.get("themecolor") ||
        (state as { [key: string]: string })?.themecolor;
      const calloutText =
        searchParams.get("calloutText") ||
        (state as { [key: string]: string })?.calloutText;

      setThemeColor(event?.configs?.widget_color || themecolor || "#31667f");
      setAppThemeColor(event?.configs?.widget_color || themecolor || "#31667f");
      setOfflineSlotsEnabled(event?.configs?.offline_enabled || false);

      if (event?.end_date) setEventEndDate(toDate(event?.end_date));

      if (event?.realtime_event?.slug)
        setRealtimeEventSlug(`${event.realtime_event.slug}/instant`);

      setQuestions(questions);
      setUserAvatar(
        (selected_user && selected_user?.avatar_url) ||
        available_users?.[0]?.avatar_url ||
        defaultWidgetAvatar ||
        ""
      );

      setOrganisationName(organisation?.name);
      setOrganisationId(organisation?.id);
      setOrganisationLogo(organisation?.logo);
      // if (embed === EMBED_TYPE_POPUP && event?.id) {
      //   postEventTrack(event?.id);
      // }

      const utm_flags = getUTMObject();

      if (embed !== EMBED_TYPE_POPUP) {
        AppTracking.widgetLoad(organisation?.workspace?.id, {
          event_id: event?.id,
          is_realtime: false,
          url: window.location.href,
          timestamp: new Date().toJSON(),
          utm_params: {
            ...utm_flags
          },
          source: 'URL',
          userAvailable: Boolean((available_users && available_users?.length) || false),
          workspace_id: organisation?.workspace?.id,
        });
      }

      window.parent.postMessage(
        {
          type: "onEventLoaded",
          isLoaded: true,
          expandAfter: event?.configs?.widget_open_sec,
          workspaceId: organisation?.workspace?.id,
          meta: {
            event_id: event?.id,
            is_realtime: false,
            url: window.location.href,
            timestamp: new Date().toJSON(),
            utm_params: {},
            source: frame === 'fullframe' ? 'buttonwidget' : 'floatingwidget',
            userAvailable: Boolean((available_users && available_users?.length) || false),
            workspace_id: organisation?.workspace?.id,
          }
        },
        "*"
      );

      window.parent.postMessage(
        {
          type: "updateCalloutText",
          value:
            event?.configs?.widget_callout_text ||
            calloutText ||
            "Let's meet and solve your need now!",
        },
        "*"
      );
      window.parent.postMessage(
        {
          type: "canShowCallout",
          value: event?.configs?.widget_callout_show !== false,
        },
        "*"
      );
      window.parent.postMessage(
        {
          type: "updateWidgetPosition",
          value: event?.configs?.widget_position || "bottomright",
        },
        "*"
      );
      window.parent.postMessage(
        {
          type: "updateThemeColor",
          value: event?.configs?.widget_color || themecolor || "#31667f",
        },
        "*"
      );
      window.parent.postMessage(
        {
          type: "updateUserAvatar",
          imgSrc: available_users?.[0]?.avatar_url || defaultWidgetAvatar || "",
        },
        "*"
      );
      // window.parent.postMessage(
      //   {
      //     type: "updateIconType",
      //     value: event?.configs?.widget_icon || '',
      //   },
      //   "*"
      // );
      isFirstLoad.current = true;
      await fetchSchedulesandSlots(
        event?.id, 
        selectedTimeZone, 
        event?.configs?.offline_enabled || false,
        questions
      );
      isFirstLoad.current = false;
    } catch (error) {
      console.error("loadEventData", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSchedulesandSlots = async (id: number, tz: string, offlineSlotsAllowed: boolean, formQuestions: FormElement[]) => {
    try {
      let currWeek = toDate(currentWeek, { timeZone: selectedTimeZone });
      const payload: any = {};
      const prefillData = getPrefillData() || prefill;
      if (prefillData && Object.keys(prefillData)?.length && formQuestions && formQuestions?.length) {
        formQuestions?.forEach((question: any) => {
          const label = question?.label || "";
          payload[question?.id] = prefillData && prefillData[label];
        });
      }
      const startDate = toDate(isValid(currWeek) ? startOfWeek(currWeek, { weekStartsOn: 1 }) : new Date(), { timeZone: selectedTimeZone });
      const endDate = toDate(isValid(currWeek) ? endOfWeek(currWeek, { weekStartsOn: 1 }) : new Date(), { timeZone: selectedTimeZone }); 
      setIsScheduleLoading(true);
      let result = null;
      if (id) {
        result = await ScheduleService.getSchedule({
          start_date: formatInTimeZone(isValid(startDate) ? startDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
          end_date: formatInTimeZone(isValid(endDate) ? endDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
          event_id: id,
          source_url: (parentURLRef.current || parentURL) || window.location.href,
          timezone: encodeURIComponent(tz || 'Asia/Calcutta'),
          ...(Object.keys(payload)?.length ? {submissions: JSON.stringify(payload)} : {})
        });
        updateSchedules(result?.data?.schedules, startDate, endDate, offlineSlotsAllowed);
        updateSlots(result?.data?.schedules, offlineSlotsAllowed); 
      }
    } catch (err) {
      console.log(err);
    }
  }

  const updateSchedules = (schedules: any, startDate: Date, endDate: Date, offlineSlotsAllowed: boolean) => {
    setIsScheduleLoading(true);
    try {

      if (offlineSlotsAllowed) {
        setSchedule(schedules?.online || []);
        setOfflineSchedule(schedules?.offline || []);
      } else {
        setSchedule(schedules || []);
      }
  
      let selectability: { [key:string]: boolean } = {};
      let dateNotSelectableInThisWeekCounter: number = 0;
      let startDateCpy: Date = startDate; 
      let selectabilitySchedules;
      if (offlineSlotsAllowed) {
        const resultSchedules: any = schedules;
        const online = schedules?.online;
        const offline = schedules?.offline;
        
        if (slotMode && resultSchedules[slotMode] && Object.keys(resultSchedules[slotMode])?.length){
          setSlotMode(slotMode);
          selectabilitySchedules = resultSchedules[slotMode];
        } else {
          if (slotMode === 'offline' && online && Object.keys(online)?.length) {
            setSlotMode('offline');
            selectabilitySchedules = online;
          }
          if (slotMode === 'online' && offline && Object.keys(offline)?.length) {
            setSlotMode('offline');
            selectabilitySchedules = offline;
          }
        }
        selectabilitySchedules = { ...online, ...offline };
        const selectedDateNew = selectedDateRef.current && isValid(selectedDateRef.current) ? selectedDateRef.current : new Date();

        const date = selectedDateNew && !isAfter(startDate, selectedDateNew) ? selectedDateNew : (selectabilitySchedules && Object.keys(selectabilitySchedules)?.[0]);
        if (date) {
          const newDate = toDate(isValid(new Date(date)) ? date : new Date(), { timeZone: selectedTimeZone });
          setSelectedDate(newDate);
          selectedDateRef.current = newDate;
        }
      } else {
        selectabilitySchedules = schedules;
        const date = selectedDateRef.current || (selectabilitySchedules && Object.keys(selectabilitySchedules)?.[0]);
        if (date) {
          const newDate = toDate(isValid(new Date(date)) ? date : new Date(), { timeZone: selectedTimeZone });
          setSelectedDate(newDate);
          selectedDateRef.current = newDate;
        }
      }
      while (isSameDay(startDateCpy, endDate) || isBefore(startDateCpy, endDate)) {
        const date = format(startDateCpy, "yyyy-MM-dd");
        if (selectabilitySchedules && selectabilitySchedules?.[date]) {
          selectability[date] = true;
        } else {
          if (isThisWeek(startDateCpy, { weekStartsOn: 1 })) {
            dateNotSelectableInThisWeekCounter++;
          }
          selectability[date] = false;
        }
        startDateCpy = addDays(startDateCpy, 1);
      }
      setDateSelectability(selectability);
      if (dateNotSelectableInThisWeekCounter >= 7) {
        const newWeek = addWeeks(startOfWeek(startDate, { weekStartsOn: 1 }), 1)
        setCurrentWeek(newWeek);
        return;
      }
      setIsScheduleLoading(false);
    } catch (err) {
      setIsScheduleLoading(false);
      console.log(err);
    }
  }

  const updateSlots = (schedules: any, offlineSlotsAllowed: boolean, selectFirstSlot: boolean = true) => {
    setIsSlotsLoading(true);
    try {

      const selDate = selectedDateRef.current;
      if (offlineSlotsAllowed) {
        const date = format(
          selDate && isValid(selDate) ? selDate : new Date(), 
          "yyyy-MM-dd"
        );
        const slots = schedules?.online[date] || [];
         
        const offlineSlots = schedules?.offline[date] || [];
        setSlots(
          (slots && slots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        );
        setOfflineSlots(
          (offlineSlots && offlineSlots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        )
        if (slotMode === 'offline') {
          const firstSlot = selectFirstSlot ? 
            getFirstSlot(offlineSlots) : 
            { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
          if (firstSlot) {
            setSelectedSlot({
              startTime: new Date(firstSlot?.start_time),
              endTime: new Date(firstSlot?.end_time),
            })
          }
        } else {
          const firstSlot = selectFirstSlot ? 
            getFirstSlot(slots) : 
            { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
          if (firstSlot) {
            setSelectedSlot({
              startTime: new Date(firstSlot?.start_time),
              endTime: new Date(firstSlot?.end_time),
            })
          }
        }
      } else {
        const date = format(
          selDate && isValid(selDate) ? selDate : new Date(), 
          "yyyy-MM-dd"
        )
        const slots =
          schedules[date] || [];
  
        setSlots(
          (slots && slots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        );
        const firstSlot = selectFirstSlot ? 
          getFirstSlot(slots) : 
          { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
        if (firstSlot) {
          setSelectedSlot({
            startTime: new Date(firstSlot?.start_time),
            endTime: new Date(firstSlot?.end_time),
          })
        }
      }
      setIsSlotsLoading(false);
    } catch (err) {
      setIsSlotsLoading(false);
      console.log(err);
    }
  }

  const onSlotConfirmed = async (currentTimeSlot: boolean = false) => {
    if (prefill && Object.keys(prefill)?.length) {
      const slot: EventSlot = {
        startTime: currentTimeSlot ? new Date() : (selectedSlot?.startTime || new Date()),
        endTime: addMinutes(
          currentTimeSlot ? new Date() : (selectedSlot?.startTime || new Date()),
          eventDuration
        ),
        timeZone: selectedTimeZone,
      };

      const visibleStepIdsArr = visibleStepIds.current && Object.values(visibleStepIds.current);

      const found =
        questions &&
        questions?.filter(
          (question: any) =>
            prefill &&
            !Object.keys(prefill)?.find((key) => {
              if (key === question?.label) {
                if (question?.is_required) {
                  if (question?.step_id && !visibleStepIdsArr.includes(question?.step_id)) {
                    return true;
                  }
                  if (prefill[key]) {
                    return true;
                  }
                  return false;
                }
                return true;
              }
              return false;
            })
      );

      const equalLengths =
        (questions && questions?.length) <=
        (prefill && Object.keys(prefill)?.length);

      if (equalLengths && found && !found?.length) {
        const payload: any = {};
        questions?.map((question: any) => {
          const label = question?.label || "";
          payload[question?.id] = prefill && prefill[label];
        });
        await onFormSubmit(payload, slot, currentTimeSlot);
        return;
      }
    }
    if (!selectedSlot?.startTime && !currentTimeSlot) return;
    
    const startTime = formatInTimeZone(
      currentTimeSlot ? new Date() : (selectedSlot?.startTime || new Date()),
      selectedTimeZone,
      "yyyy-MM-dd'T'HH:mm:ssxxx"
    );
    navigate(`${pathname}/${startTime}${search}`, {
      replace: false,
      state: { referrer },
    });
  };

  const onFormSubmit = async (data: Record<string, string>, slot: EventSlot, isBookedNow: boolean = false) => {
    setIsLoading(true);
    setConfirmedSlot(slot);
    Tracking.eventFormSubmit();
    if (embedType !== EMBED_TYPE_POPUP) {
      AppTracking.scheduledFormSubmit(workspaceId || 0, {
        event_id: eventId,
        is_realtime: false,
        url: window.location.href,
        timestamp: new Date().toJSON(),
        utm_params: {
          ...(UTMFlags)
        },
        source: 'URL',
        userAvailable: isUserAvailable,
        workspace_id: workspaceId
      });
    }
    
    window.parent.postMessage(
      {
        type: "onFormSubmit",
        workspaceId: workspaceId,
        meta: {
          event_id: eventId,
          is_realtime: false,
          url: window.location.href,
          timestamp: new Date().toJSON(),
          utm_params: {},
          source: embedType === EMBED_TYPE_POPUP ? frame === 'fullframe' ? 'buttonwidget' : 'floatingwidget' : 'URL',
          userAvailable: isUserAvailable,
          workspace_id: workspaceId
        }
      },
      "*"
    );
    try {
      const response = await ScheduleService.postSchedule({
        event_id: Number(eventId),
        time: slot?.startTime.toString() || "",
        answers: data,
        timezone: selectedTimeZone,
        source_url: parentURL || window.location.href,
        slot_mode: slotMode,
        instant_slot: isBookedNow || false,
        ...(UTMFlags && Object.keys(UTMFlags)?.length ? {utm_flags: {...UTMFlags}} : {}),
        ...(participants?.length ? { participants } : {})
      });
      const {
        room,
        location,
        booking,
        event
      } = response?.data;
      
      const bookingId = booking?.id;
      const scheduledTime = booking?.time || '';
      const meetUrl = room?.url || '';
      const meetRoomId = room?.roomId || '';
      const slug = event?.slug || '';
      
      let url: URL | null = null;
      if (room?.url) {
        url = new URL(room?.url);
        setMeetLink(url);
      }
      
      const bookedSlot = formatInTimeZone(
        booking?.time,
        booking?.timezone,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )

      const payload = {
        bookedSlot,
        scheduledTime,
        meetUrl,
        rescheduleUrl: `${window.origin}${slug}/reschedule-meeting?id=${bookingId}&room=${meetRoomId}`,
        cancelUrl: `${window.origin}${slug}/cancel-meeting?id=${bookingId}&room=${meetRoomId}`,
      }
      setScheduledSlot(slot);

      window.parent.postMessage(
        {
          type: "zt_meeting_confirmed",
          value: payload
        },
        "*"
      );  
      setSiteLocation(location);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response?.status === 409) {
        if (err?.response?.data?.code === 'SLOT_NOT_AVAILABLE') {
          Tracking.eventSlotNotAvailable();
        } else if (err?.response?.data?.code === 'COULD_NOT_ALLOCATE_USER'){
          Tracking.eventCouldNotAllocateUser()
        }
        const newURL = new URL(window.location.href);
        newURL.searchParams.delete('date');
        newURL.searchParams.delete('month');

        window.location.href = new URL(
          `/${params.org}${params.slug ? `/${params.slug}` : ''}${newURL?.search ? `${newURL?.search}&referrer=noslot`: '?referrer=noslot'}${prefill ? `&prefill=${encodeURIComponent(JSON.stringify(prefill))}` : ''}`,
          APP_BASE_URL
        ).href
      } else {
        setSchedulingFailed(true);
      }
    } finally {
      setIsInstantSlot(false);
    }
  };

  const getUTMObject = () => {
    const utm_term = searchParams.get('utm_term') || (state as { [key: string]: string })?.utm_term;
    const utm_source = searchParams.get('utm_source') || (state as { [key: string]: string })?.utm_source;
    const utm_content = searchParams.get('utm_content') || (state as { [key: string]: string })?.utm_content;
    const utm_campaign = searchParams.get('utm_campaign') || (state as { [key: string]: string })?.utm_campaign;
    const utm_medium =  searchParams.get('utm_medium') || (state as { [key: string]: string })?.utm_medium;

    const utm_flags = {
      ...(utm_term ? { utm_term } : {}),
      ...(utm_source ? { utm_source } : {}),
      ...(utm_content ? { utm_content } : {}),
      ...(utm_campaign ? { utm_campaign } : {}),
      ...(utm_medium ? { utm_medium } : {}),
    }

    return utm_flags;
  }

  const isNormalWorkingHours = (date: string) => {
    if (isValid(new Date(date))) {
      const time = formatInTimeZone(isValid(new Date(date)) ? new Date(date) : new Date(), selectedTimeZone, 'HH');
      if (Number(time) >= 8 && Number(time) <= 20) {
        return true
      }
      return false
    }
    return false;
  }

  const getFirstSlot = (slotsArr: any[]) => {
    let firstSlot: any = {};
    if (!(slotsArr?.[0]?.start_time && isNormalWorkingHours(slotsArr?.[0]?.start_time))) {
      const workingHourSlots = slotsArr.filter((date: any) => isNormalWorkingHours(date?.start_time));
      if (workingHourSlots && workingHourSlots?.length) {
        firstSlot = workingHourSlots?.[0];
      } else {
        firstSlot = slotsArr?.[0];
      }
    }
    else {
      firstSlot = slotsArr?.[0];
    }
    return firstSlot;
  }

  useEffect(() => {
    if (!params.date) {
      setConfirmedSlot(undefined);
    }
    if (params.date && eventDuration) {
      const slot = {
        startTime: toDate(params.date),
        endTime: addMinutes(toDate(params.date), eventDuration),
        timeZone: selectedTimeZone,
      };
      setConfirmedSlot(slot);
    }
  }, [params.date, eventDuration]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      selectedDateRef.current = undefined;
      fetchSchedulesandSlots(
        eventId!,
        selectedTimeZone || 'Asia/Calcutta', 
        offlineSlotsEnabled || false,
        questions
      );
    }
  }, [currentWeek, selectedTimeZone]);

  useEffect(() => {
    if (!isFirstLoad.current && !slotManuallyChanged.current) {
      updateSlots(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule, 
        offlineSlotsEnabled
      )
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isScheduleLoading) {
      updateSchedules(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule, 
        startOfWeek(currentWeek, { weekStartsOn: 1 }),
        endOfWeek(currentWeek, { weekStartsOn: 1 }),
        offlineSlotsEnabled
      );
      updateSlots(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule,
        offlineSlotsEnabled,
        !slotManuallyChanged.current
      );
    }
  }, [slotMode]);

  // const onSlotConfirmed = () => {
  //   if (!selectedSlot?.startTime) return;
  //   const startTime = format(
  //     selectedSlot?.startTime,
  //     "yyyy-MM-dd'T'HH:mm:ssxxx"
  //   );
  //   navigate(`${pathname}/${startTime}${search}`, {
  //     replace: false,
  //     state: { referrer },
  //   });
  // };

  // useEffect(() => {
  //   setEmbedType(searchParams.get("embed_type"));
  //   setFrame(searchParams.get("frame") || '');
  //   if (!searchParams.get("month")) {
  //     navigate(
  //       `${pathname}${searchSet(
  //         search,
  //         "month",
  //         format(new Date(), "yyyy-MM")
  //       )}`,
  //       { replace: true, state: { referrer } }
  //     );
  //     return;
  //   }

  //   if (!isSelectedMonthChanged.current && searchParams.get("month") 
  //     && !isThisMonth(toDate(String(searchParams.get("month"))))) {
  //     navigate(
  //       `${pathname}${searchSet(
  //         search,
  //         "month",
  //         format(new Date(), "yyyy-MM")
  //       )}`,
  //       { replace: true, state: { referrer } }
  //     );
  //     isSelectedMonthChanged.current = true;
  //     return;
  //   }

  //   const date = toDate(String(searchParams.get("date")))
  //   if ((!selectedDate && searchParams.get("date")) ||
  //     (selectedDate && !isSameDay(selectedDate, date))) {
  //       setSelectedDate(date);
  //       Tracking.eventDateSelected(String(searchParams.get("date")));
  //   }

  //   if (
  //     (!selectedMonth && searchParams.get("month")) ||
  //     (selectedMonth &&
  //       !isSameMonth(
  //         selectedMonth,
  //         toDate(String(searchParams.get("month")))
  //       ))
  //   ) {
  //     setSelectedMonth(toDate(String(searchParams.get("month"))));
  //   }
  // }, [location]);

  // useEffect(() => {
  //   try {
  //     if (selectedMonth && selectedMonth instanceof Date && selectedTimeZone && eventId) {
  //       (async () => {
  //         let startDate = isValid(selectedMonth) ? selectedMonth : new Date();
  //         let endDate = endOfMonth(isValid(selectedMonth) ? selectedMonth : new Date());
  //         if (!isSameMonth(endOfWeek(endDate, {weekStartsOn: 1}), selectedMonth)) {
  //           const newMonth = addMonths(selectedMonth, 1);
  //           endDate = endOfMonth(isValid(newMonth) ? newMonth : new Date());
  //         }
  //         if (isValid(selectedDate)) {
  //           if (schedule && Object.keys(schedule).length) {
  //             const scheduleKeys = Object.keys(schedule);
  //             const newStart = set(new Date(scheduleKeys?.[0]) || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             const newEnd = set(new Date(scheduleKeys?.[scheduleKeys.length - 1])  || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             const selDate = set(selectedDate && isValid(selectedDate) ? new Date(selectedDate) : new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             if ((isBefore(newStart, selDate) || isEqual(newStart, selDate)) && 
  //               (isAfter(newEnd, selDate) || isEqual(newStart, selDate))
  //             ) {
  //               startDate = newStart;
  //               if (!isBefore(newEnd, endDate)) {
  //                 endDate =  newEnd;
  //               }
  //             }
              
  //           } else if ((offlineSchedule && Object.keys(offlineSchedule).length)) {
  //             const scheduleKeys = Object.keys(offlineSchedule);
  //             const newStart = set(new Date(scheduleKeys?.[0]) || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             const newEnd = set(new Date(scheduleKeys?.[scheduleKeys.length - 1])  || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             const selDate = set(selectedDate && isValid(selectedDate) ? new Date(selectedDate) : new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  //             if ((isBefore(newStart, selDate) || isEqual(newStart, selDate)) && 
  //               (isAfter(newEnd, selDate) || isEqual(newStart, selDate))
  //             ) {
  //               startDate = newStart;
  //               if (!isBefore(newEnd, endDate)) {
  //                 endDate =  newEnd;
  //               }
  //             }
  //           }
  //         }

  //         // if (currentWeek && isValid(currentWeek) && 
  //         //   !isPast(currentWeek) && (isBefore(currentWeek, selectedMonth)) 
  //         // ) {
  //         //   startDate = currentWeek;
  //         // }

  //         // if (currentWeek && isValid(currentWeek) && 
  //         //   isSameMonth(addMonths(selectedMonth, 1), endOfWeek(currentWeek, {weekStartsOn: 1}))
  //         // ) {
  //         //   endDate = endOfMonth(addMonths(selectedMonth, 1));
  //         // }
  //         const payload: any = {};
  //         if (prefill && Object.keys(prefill)?.length && questions && questions?.length) {
  //           questions?.map((question: any) => {
  //             const label = question?.label || "";
  //             payload[question?.id] = prefill && prefill[label];
  //           });
  //         }
  //         setIsScheduleLoading(true);
  //         const result = await ScheduleService.getSchedule({
  //           start_date: formatInTimeZone(isValid(startDate) ? startDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
  //           end_date: formatInTimeZone(isValid(endDate) ? endDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
  //           event_id: eventId,
  //           timezone: encodeURIComponent(selectedTimeZone || 'Asia/Calcutta'),
  //           ...(Object.keys(payload)?.length ? {submissions: JSON.stringify(payload)} : {})
  //         });
  //         if (offlineSlotsEnabled) {
  //           setSchedule(result?.data?.schedules?.online);
  //           setOfflineSchedule(result?.data?.schedules?.offline);
  //         } else {
  //           setSchedule(result?.data?.schedules);
  //         }
  
  //         let selectability: Record<string, boolean> = {};
  //         let dateNotSelectableInThisWeekCounter: number = 0;
  //         while (isSameDay(startDate, endDate) || isBefore(startDate, endDate)) {
  //           const date = format(startDate, "yyyy-MM-dd");
  //           if (offlineSlotsEnabled) {
  //             const online = result?.data?.schedules?.online;
  //             const offline = result?.data?.schedules?.offline;
  //             let schedules;
  //             if (slotMode === 'offline') {
  //               if (offline && Object.keys(offline)?.length) {
  //                 schedules = offline;
  //               }
  //               if (online && Object.keys(online)?.length) {
  //                 schedules = online;
  //               }
  //             }  
  //             if (slotMode === 'online')  {
  //               if (online && Object.keys(online)?.length) {
  //                 schedules = online;
  //               }
  //               if (offline && Object.keys(offline)?.length) {
  //                 schedules = offline;
  //               }
  //             }
  //             if (schedules && schedules?.[date]) {
  //               selectability[date] = true;
  //             } else {
  //               if (isThisWeek(startDate, {weekStartsOn: 1}) && isThisMonth(selectedMonth)) {
  //                 dateNotSelectableInThisWeekCounter++;
  //               }
  //               selectability[date] = false;
  //             }
  //           } else {
  //             const schedules = result?.data?.schedules;
  //             if (schedules && schedules?.[date]) {
  //               selectability[date] = true;
  //             } else {
  //               if (isThisWeek(startDate, {weekStartsOn: 1}) && isThisMonth(selectedMonth)) {
  //                 dateNotSelectableInThisWeekCounter++;
  //               }
  //               selectability[date] = false;
  //             }
  //           }
  //           startDate = addDays(startDate, 1);
  //         }
  //         if (dateNotSelectableInThisWeekCounter >= 7) {
  //           const newWeek = addWeeks(startOfWeek(currentWeek, {weekStartsOn: 1}), 1)
  //           if (!isThisMonth(newWeek)) {
  //             isSelectedMonthChanged.current = true;
  //             const newMonth = startOfMonth(newWeek);
  //             const formattedMonth = format(
  //               toDate(newMonth),
  //               "yyyy-MM"
  //             );
  //             let params = searchSet(
  //               search,
  //               "month",
  //               formattedMonth
  //             );
  //             navigate(`${pathname}${params}`, {
  //               replace: true,
  //               state: { referrer },
  //             });
  //             setCurrentWeek(newWeek);
  //             return;
  //           }
  //           setCurrentWeek(newWeek);
  //         }
  //         setDateSelectability(selectability);

  //         const online = result?.data?.schedules?.online;
  //         const offline = result?.data?.schedules?.offline;
  //         let schedules;
  //         if (offlineSlotsEnabled) {
  //           if (slotMode === 'offline') {
  //             if (offline && Object.keys(offline)?.length) {
  //               setSlotMode('offline');
  //               schedules = offline;
  //             }
  //             if (online && Object.keys(online)?.length) {
  //               setSlotMode('online');
  //               schedules = online;
  //             }
  //           }  
  //           if (slotMode === 'online')  {
  //             if (offline && Object.keys(offline)?.length) {
  //               setSlotMode('offline');
  //               schedules = offline;
  //             }
  //             if (online && Object.keys(online)?.length) {
  //               setSlotMode('online');
  //               schedules = online;
  //             }
  //           }
  //         } else {
  //           schedules = result?.data?.schedules;
  //         }
          
  //         if (!isDateFirstTimeSelected.current && schedules && Object.keys(schedules)?.length) {
  //           const firstAvailableDate = Object.keys(schedules)?.[0];
  //           const formattedMonth = format(
  //             toDate(firstAvailableDate),
  //             "yyyy-MM"
  //           );
  //           const formattedDate = format(
  //             toDate(firstAvailableDate),
  //             "yyyy-MM-dd"
  //           );
  //           let params = searchSet(
  //             search,
  //             "month",
  //             formattedMonth
  //           );
  //           params = searchSet(
  //             params,
  //             "date",
  //             formattedDate
  //           );
  //           navigate(`${pathname}${params}`, {
  //             replace: true,
  //             state: { referrer },
  //           });
  //           isDateFirstTimeSelected.current = true;
  //         } 
  //         setIsScheduleLoading(false);
  //       })();
  //     }
  //   } catch (err) {
  //     setIsScheduleLoading(false);
  //     console.log(err);
  //   }
  // }, [selectedMonth, selectedTimeZone, eventId]);

  // useEffect(() => {
  //   try {
  //     if (selectedDate && isValid(selectedDate) && selectedMonth && isValid(selectedMonth)  && eventId) {
  //       if ((schedule || offlineSchedule) && isSameMonth(selectedDate, selectedMonth)) {
  //         let slots: any = [];
  //         let offlineSlots: any = [];
  //         if (offlineSlotsEnabled) {
  //           offlineSlots = offlineSchedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ] || [];
  //           slots = schedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ] || [];
  //           setOfflineSlots(
  //             (offlineSlots && offlineSlots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           )
  //           setSlots(
  //             (slots && slots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           );
  //         } else {
  //           slots = schedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ];
  //           setSlots(
  //             (slots && slots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           );
  //         } 
            
  //         if (slotMode === 'offline') {
  //           const firstSlot = getFirstSlot(offlineSlots);
  //           if (firstSlot) {
  //             setSelectedSlot({
  //               startTime: new Date(firstSlot?.start_time),
  //               endTime: new Date(firstSlot?.end_time),
  //             })
  //           }
  //         } else {
  //           const firstSlot = getFirstSlot(slots);
  //           if (firstSlot) {
  //             setSelectedSlot({
  //               startTime: new Date(firstSlot?.start_time),
  //               endTime: new Date(firstSlot?.end_time),
  //             })
  //           }
  //         }
  //       } else {
  //         (async () => {
  //           let startDate = isValid(selectedDate) ? selectedDate : new Date();
  //           let endDate = endOfMonth(isValid(selectedDate) ? selectedDate : new Date());
  //           if (!isSameMonth(endOfWeek(endDate, {weekStartsOn: 1}), selectedMonth)) {
  //             const newMonth = addMonths(selectedMonth, 1);
  //             endDate = endOfMonth(isValid(newMonth) ? newMonth : new Date());
  //           }
  //           const payload: any = {};
  //           if (prefill && Object.keys(prefill)?.length && questions && questions?.length) {
  //             questions?.map((question: any) => {
  //               const label = question?.label || "";
  //               payload[question?.id] = prefill && prefill[label];
  //             });
  //           }
  //           setIsSlotsLoading(true);
  //           const result = await ScheduleService.getSchedule({
  //             start_date: formatInTimeZone(isValid(startDate) ? startDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
  //             end_date:formatInTimeZone(isValid(endDate) ? endDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
  //             event_id: eventId,
  //             timezone: encodeURIComponent(selectedTimeZone || 'Asia/Calcutta'),
  //             ...(Object.keys(payload)?.length ? {submissions: JSON.stringify(payload)} : {})
  //           });
  //           if (offlineSlotsEnabled) {
  //             const slots = result?.data?.schedules?.online[
  //               formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //             ] || []; 
  //             const offlineSlots = result?.data?.schedules?.offline[
  //               formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //             ] || [];
  //             setSlots(
  //               (slots && slots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             );
  //             setOfflineSlots(
  //               (offlineSlots && offlineSlots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             )
  //             if (slotMode === 'offline') {
  //               const firstSlot = getFirstSlot(offlineSlots);
  //               if (firstSlot) {
  //                 setSelectedSlot({
  //                   startTime: new Date(firstSlot?.start_time),
  //                   endTime: new Date(firstSlot?.end_time),
  //                 })
  //               }
  //             } else {
  //               const firstSlot = getFirstSlot(slots);
  //               if (firstSlot) {
  //                 setSelectedSlot({
  //                   startTime: new Date(firstSlot?.start_time),
  //                   endTime: new Date(firstSlot?.end_time),
  //                 })
  //               }
  //             }
  //           } else {
  //             const slots =
  //               result?.data?.schedules[
  //                 formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //               ] || [];
  
  //             setSlots(
  //               (slots && slots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             );
  //             const firstSlot = getFirstSlot(slots);
  //             if (firstSlot) {
  //               setSelectedSlot({
  //                 startTime: new Date(firstSlot?.start_time),
  //                 endTime: new Date(firstSlot?.end_time),
  //               })
  //             }
  //           }
  //           setIsSlotsLoading(false);
  //         })()
  //       }
  //     }
  //   } catch (err) {
  //     setIsSlotsLoading(false);
  //     console.log(err);
  //   }
    
  // }, [selectedDate, eventId]);

  useEffect(() => {
    const prefillData =
      searchParams.get("prefill") ||
      (state as { [key: string]: string })?.prefill;
    const themecolor =
      searchParams.get("themecolor") ||
      (state as { [key: string]: string })?.themecolor;
    let phonenumber =
      searchParams.get("number") ||
      (state as { [key: string]: string })?.number;
    const email =
      searchParams.get("email") ||
      (state as { [key: string]: string })?.email;
    const parentLocation = 
      searchParams.get("parentURL") ||
      (state as { [key: string]: string })?.parentURL;
    const customerName =
      searchParams.get("name") ||
      (state as { [key: string]: string })?.name;
    const companyName =
      searchParams.get("company") ||
      (state as { [key: string]: string })?.company;
    const userId =
      searchParams.get("user_id") ||
      (state as { [key: string]: string })?.user_id;
    const prefilltype =
      searchParams.get("prefilltype") ||
      (state as { [key: string]: string })?.prefilltype;
    const allowrestartflow =
      searchParams.get("allowrestartflow") ||
      (state as { [key: string]: string })?.allowrestartflow;
    const enableBookNow =
      searchParams.get("enablebooknow") ||
      (state as { [key: string]: string })?.enablebooknow;
    const embedType = 
      searchParams.get("embed_type") ||
      (state as { [key: string]: string })?.embed_type;
    const frame = 
      searchParams.get("frame") ||
      (state as { [key: string]: string })?.frame;
      
    setEmbedType(embedType);
    setFrame(frame);
      

    const utm_flags = getUTMObject();

    if (utm_flags && Object.keys(utm_flags)?.length) {
      setUTMFlags(utm_flags)
    }

    if (phonenumber) {
      if (phonenumber && phonenumber.includes(' ') && phonenumber.indexOf(' ') === 0) {
        phonenumber = phonenumber.replace(' ', '+');
      }
      if (phonenumber && phonenumber.includes(' ')) {
        phonenumber = phonenumber.replaceAll(' ', '');
      }
      setPrefillPhoneNumber(phonenumber);
    }

    if (email) {
      setPrefillEmail(email);
    }

    if (customerName) {
      setPrefillCustomerName(customerName);
    }

    if (companyName) {
      setPrefillCompanyName(companyName);
    }

    if (allowrestartflow && allowrestartflow === 'false' ) {
      setAllowRestartFlow(false);
    }

    if (enableBookNow) {
      setInstantScheduledBookingEnabled(Boolean(enableBookNow === 'true'));
    }

    if (prefillData) {
      const parsedData: { [key: string]: string } =
        typeof prefillData === "string" ? JSON.parse(prefillData) : prefillData;
      if (!prefill) setPrefill(parsedData);
      navigate(`${pathname}${searchRemove(searchRemove(search, "prefill"), 'parentURL')}`, {
        replace: true,
        state: { referrer },
      });
    }

    if (searchHas(search, 'name') || searchHas(search, 'email') || searchHas(search, 'number') || searchHas(search, 'company') || searchHas(search, 'prefilltype')) {
      setPrefillType(prefilltype)
      navigate(
        `${pathname}${searchRemove(
          searchRemove(
            searchRemove(
              searchRemove(searchRemove(search, "company"), "number"),
              "email"
            ),
            "name"
          ),
          "prefilltype"
        )}`,
        {
          replace: true,
          state: { referrer },
        }
      );
    }

    if (searchHas(search, 'parentURL')) {
      setParentURL(parentLocation);
      parentURLRef.current = parentLocation
      navigate(`${pathname}${searchRemove(search, 'parentURL')}`, {
        replace: true,
        state: { referrer },
      });
    }

    if (searchHas(search, 'user_id')) {
      setSelectedUserId(userId);
      navigate(`${pathname}${searchRemove(search, 'user_id')}`, {
        replace: true,
        state: { referrer },
      });
    }

    if (themecolor) {
      setThemeColor(themecolor);
      setAppThemeColor(themecolor);
    }
    loadEventData(userId, prefilltype);
  }, []);

  return (
    <Layout>
      <div
        className={`
        flex flex-col h-screen md:min-h-screen 
        ${
          embedType === EMBED_TYPE_POPUP
            ? "bg-transparent"
            : "bg-background-app"
        }
      `}
      >
        <ScheduledContext.Provider value={{themeColor}}>
          <App />
        </ScheduledContext.Provider>
      </div>
    </Layout>
  );
}

export default ScheduledApp;

// const onSlotConfirmed = async () => {
//   if (prefill && Object.keys(prefill)?.length) {
//     const slot = {
//       startTime: selectedSlot?.startTime || new Date(),
//       endTime: addMinutes(
//         selectedSlot?.startTime || new Date(),
//         eventDuration
//       ),
//       timeZone: selectedTimeZone,
//     };
//     setConfirmedSlot(slot);

//     const found =
//       questions &&
//       questions?.filter(
//         (question: any) =>
//           prefill &&
//           !Object.keys(prefill)?.find((key) => key === question?.label)
//       );
//     const equalLengths =
//       (questions && questions?.length) ===
//       (prefill && Object.keys(prefill)?.length);
//     if (equalLengths && found && !found?.length) {
//       const payload: any = {};
//       questions?.map((question: any) => {
//         const label = question?.label || "";
//         payload[question?.id] = prefill && prefill[label];
//       });
//       await onFormSubmit(payload, slot);
//       return;
//     }
//   }
//   if (!selectedSlot?.startTime) return;
//   const startTime = format(
//     selectedSlot?.startTime,
//     "yyyy-MM-dd'T'HH:mm:ssxxx"
//   );
//   navigate(`${pathname}/${startTime}${search}`, {
//     replace: false,
//     state: { referrer },
//   });
// };

// const onFormSubmit = async (data: Record<string, string>, slot: Slot ) => {
//   Tracking.eventFormSubmit();
//   try {
//     const response = await ScheduleService.postSchedule({
//       event_id: Number(eventId),
//       time: slot?.startTime.toString() || "",
//       answers: data,
//       timezone: selectedTimeZone,
//     });
//     const {
//       room: { url },
//     } = response?.data;

//     setScheduledSlot(confirmedSlot);
//     setMeetLink(new URL(url));
//   } catch (err) {
//     setSchedulingFailed(true);
//   }
// };